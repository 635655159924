import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from '../../lib/i18n';

const PortofolioListCategoriesGrid = () => {
    const { t } = useTranslation()
    return (
        <>
            <section className="portofolioListCategoriesFilterContainer">
                <div className="row" id="Grid">
                    <div className="">
                        {t('portofolio:Projects', { returnObjects: true }).map((item) => {
                            return (
                                <div className={`col-lg-4 col-sm-6 col-md-4 mix mix_all portofolioItemContainer ${item.category}`}>
                                    <div className="singlefolio">
                                        <img src={require(`../../assets/images/portfolio/${item.mainImage}`)} alt="" />
                                        <div className="folioHover">
                                            <h4>{item.category}</h4>
                                            <br />
                                            <h3>
                                                <Link to={`/portofolio-detail/${item.key}`}>{item.name}</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default PortofolioListCategoriesGrid;
