import React from 'react';
import { useTranslation } from '../../lib/i18n';

const PrivacyContent = () => {
    const { t } = useTranslation();

    return (
        <div dangerouslySetInnerHTML={{ __html: t('policy:Privacy Policy') }} />
    );
}

export default PrivacyContent;
