import React, {Component} from 'react';
import ContactForm from "./ContactForm";
import * as ContactApi from "../../api/contact";

class ContactFormContainer extends Component {
state={
    submitSuccess: undefined,
    submitError:undefined
};

    onSubmit = async (data) => {
        try{
            await this.setState({submitSuccess:false, submitError:false});
        console.log(data);

      const response= await ContactApi.sendContact(data);

        console.log(response);

        return this.setState({submitSuccess: true})
        }catch(error){
            console.log(error);
            return this.setState({submitError: true})
        }
    };

    render() {
        return (
            <>
                <ContactForm onSubmit={this.onSubmit}
                             submitSuccess={this.state.submitSuccess}
                             submitError={this.state.submitError}/>
            </>
        );
    }
}

export default (ContactFormContainer);