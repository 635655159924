import React from 'react';
import { Trans } from '../../lib/i18n';

const ImageBanner = () => {
    return (
        <>
            <>
                <section className="imageBanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner_content text-center">
                                    <h4><Trans i18nKey='Image Banner.Subtitle' /></h4>
                                    <h2><Trans i18nKey="Image Banner.Title" /></h2>
                                    <p className={"margin-top-subtitle"}><Trans i18nKey="Image Banner.Description" /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </>
    );
};

export default ImageBanner;
