import React from 'react';
import image1 from "../../assets/images/about/about_us.png";
import { Trans, useTranslation } from '../../lib/i18n';

const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="commonSection ab_agency no-padding-bottom about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 PR_79 aboutUsPicture" style={{ backgroundImage: `url(${image1})` }}>
                            <div className="row about-us-padding-bottom text-left">
                                <div className="col-sm-6">
                                    <h4 className="sub_title">{t('AboutUs.Subtitle')}</h4>
                                    <h2 className="sec_title br MB_45"><Trans i18nKey="AboutUs.Title" /></h2>
                                </div>
                            </div>
                            <div className="row about-us-padding-top">
                                <div className="col-sm-6" />
                                <div className="col-sm-6 text-right">
                                    <h4 className="sub_title">{t('AboutUs.Company Start')}</h4>
                                    <h2>{t('AboutUs.Company Start Date')}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
