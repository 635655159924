import React from "react";

const PageTitle = (props) => {
    const {sectionClassName, title, subTitle,children} = props;

    return <>
        <section className={`commonSection no-padding-bottom page-title ${sectionClassName}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h4 className="widget_title red_color">{subTitle}</h4>
                        <h2 className="sec_title white">{title}</h2>
                        <br/>
                    </div>
                </div>

                {children}
            </div>
        </section>
    </>
}

export default PageTitle