import React from 'react';

import { ReactComponent as NativeAppDevelopmentIcon } from '../../assets/images/services/native-app-development.svg'
import { ReactComponent as ProductMarketStrategyIcon } from '../../assets/images/services/product-market-strategy.svg'
import { ReactComponent as QaTestingIcon } from '../../assets/images/services/qa-testing.svg'
import { ReactComponent as UiUxDesignIcon } from '../../assets/images/services/ui-ux-design.svg'
import { Trans, useTranslation } from '../../lib/i18n';

const Services = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <section className="service_section commonSection flipper-min-height">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="sec_title white">{t('HomePage.Services.Title')}</h2>
                            <h4 className="sub_title red_color">{t('HomePage.Services.Description')}</h4>
                            <br />
                        </div>
                    </div>
                    <div className="row custom_column">
                        <div className="col-md-3">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <ProductMarketStrategyIcon />
                                        </div>
                                        <h3>{t('HomePage.Services.IT Development.Title')}</h3>
                                        <h5>{t('HomePage.Services.IT Development.Description')}</h5>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <ProductMarketStrategyIcon />
                                        </div>
                                        <h3><Trans i18nKey="HomePage.Services.IT Development.Back.Title" /></h3>
                                        <ul className={"text-left"}>
                                            {t('HomePage.Services.IT Development.Back.Services', { returnObjects: true })?.map(service => <li key={service}>{service}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <UiUxDesignIcon />
                                        </div>
                                        <h3>{t('HomePage.Services.Audit.Title')}</h3>
                                        <h5>
                                            {t('HomePage.Services.Audit.Description')}
                                        </h5>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <UiUxDesignIcon />
                                        </div>
                                        <h3><Trans i18nKey="HomePage.Services.Audit.Back.Title" /></h3>
                                        <ul className={"text-left"}>
                                            {t('HomePage.Services.Audit.Back.Services', { returnObjects: true })?.map(service => <li key={service}>{service}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <NativeAppDevelopmentIcon />
                                        </div>
                                        <h3>{t('HomePage.Services.Maintenance.Title')}</h3>
                                        <h5>{t('HomePage.Services.Maintenance.Description')}</h5>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <NativeAppDevelopmentIcon />
                                        </div>
                                        <h3><Trans i18nKey="HomePage.Services.Maintenance.Back.Title"/></h3>
                                        <ul className={"text-left"}>
                                            {t('HomePage.Services.Maintenance.Back.Services', { returnObjects: true })?.map(service => <li key={service}>{service}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <QaTestingIcon />
                                        </div>
                                        <h3>{t('HomePage.Services.Consulting.Title')}</h3>
                                        <h5>{t('HomePage.Services.Consulting.Description')}</h5>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <QaTestingIcon />
                                        </div>
                                        <h3><Trans i18nKey="HomePage.Services.Consulting.Back.Title"/></h3>
                                        <ul className={"text-left"}>
                                            {t('HomePage.Services.Consulting.Back.Services', { returnObjects: true })?.map(service => <li key={service}>{service}</li>)}
                                        </ul>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Services;
