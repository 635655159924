import React, {Component} from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import ContactForm from "../../components/ContactForm";
import ContactMap from "../../components/ContactMap";

export default class ContactPage extends Component {
    render() {
        return (
            <>
                <SearchForm/>
                <ContactForm/>
                <ContactMap/>
                <Footer/>
            </>
        );
    }
};
