import React from 'react';
import {withRouter} from 'react-router-dom';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        const {$} = window;

        if (this.props.location.pathname !== prevProps.location.pathname) {
            $('.page-container').animate({scrollTop: 0}, 300)
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);
