import React, {Component} from 'react';
import Testimonials from "../../components/Testimonials";
import SearchForm from "../../components/SearchForm";
import PopupMenu from "../../components/PopupMenu";
import Services from "../../components/Services";
import BestAgency from "../../components/BestAgency";
import FiveReasons from "../../components/FiveReaseons";
import Team from "../../components/Team";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import HomePageBanner from "../../components/HomePageBanner";
import { Trans } from '../../lib/i18n';

export default class HomePage extends Component {
    render() {
        return (
            <>
                <SearchForm/>
                <HomePageBanner/>
                <Services/>
                <BestAgency/>
                <FiveReasons/>
                <Team/>
                <GetYourQuote title={<Trans i18nKey="Common.Let's start"/>}/>
                <Footer/>
            </>
        );
    }
};
