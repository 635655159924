import React from 'react';
import image1 from "../../assets/images/about/1.jpg";
import image2 from "../../assets/images/home/product_strategy.png";
import { Trans, useTranslation } from '../../lib/i18n';

const BestAgency = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="commonSection ab_agency">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-md-6 PR_79">
                            <h4 className="sub_title">{t('HomePage.BestAgency.Subtitle')}</h4>
                            <h2 className="sec_title MB_45"><Trans i18nKey="HomePage.BestAgency.Title" /></h2>
                            <p className="sec_desc">
                                <Trans i18nKey="HomePage.BestAgency.Description" />
                            </p>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-md-6">
                            <img src={image2} alt="" className="img_full_width" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BestAgency;