import React, {Component} from 'react';
import Team from './Team';

const {$} = window;

class TeamContainer extends Component {
  componentDidMount() {

    this.initTeamSlider();
  }

  componentWillUnmount() {
    // $('.team_slider').unslick();
  }

  initTeamSlider() {
    try {
      if ($('.team_slider').length > 0) {
        $('.team_slider').slick({
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 3,
          dots: true,
          arrows: false,
          centerMode: true,
          centerPadding: '150px',
          responsive: [
            {
              breakpoint: 1600,
              settings: {
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 4
              }
            },
            {
              breakpoint: 1199,
              settings: {
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 3
              }
            },
            {
              breakpoint: 991,
              settings: {
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 2
              }
            },
            {
              breakpoint: 600,
              settings: {
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 1
              }
            }
          ]
        });
      }
    } catch (e) {
      setTimeout(() => this.initTeamSlider(), 700);
    }
  }

  render() {
    return (
      <>
        <Team/>
      </>
    );
  }
}

export default TeamContainer;
