import React, {Fragment, useState} from "react";
import PropTypes from 'prop-types';
import TextLoop from "react-text-loop";

const millisecondsToSeconds = (val = 0) => {
    return val / 1000;
}

const cssSeconds = (val = 0) => {
    return `${millisecondsToSeconds(val)}s`;
}

export const TextAnimation = ({children, interval = 4000, delay, className, withSpace}) => {
    const [firstRender, setFirstRender] = useState(false);

    return (
        <Fragment>
        <span className="css-typing">
            <TextLoop interval={interval} delay={delay} className={className} mask={true}
                      onChange={() => setFirstRender(true)}>
            {children.map((child, index) => (<Fragment key={index}>
                <p style={{
                    animationDuration: cssSeconds(interval / 2),
                    animationDelay: delay && !firstRender ? cssSeconds(delay) : cssSeconds(0)
                }}>
                    {withSpace ? <>&nbsp;</> : null}
                    {child}
                    {withSpace ? <>&nbsp;</> : null}
                </p>
            </Fragment>))}
            </TextLoop>
        </span>
        </Fragment>);
};

TextAnimation.propTypes = {
    interval: PropTypes.number,
    delay: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    withSpace: PropTypes.bool
}