import React from "react";
import cyberRisks from "../../assets/images/audit/cyber-risks.png";
import { useTranslation, Trans } from "../../lib/i18n";

const ReportsOfCyberRisks = () => {
    const { t } = useTranslation();
    
    return <>
        <section className="commonSection no-padding-bottom audit serviceDetailBackgroundBlack">
            <div className="row serviceDetailBackgroundWhite">
                <div className="col-12 aboutPicture" style={{ backgroundImage: `url(${cyberRisks})` }}>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="row my-4">
                                <div className="col-xs-2 col-md-2 col-lg-2 col-xl-3" />
                                <div className="col-xs-8 col-md-8 col-lg-8 col-xl-6">
                                    <div className="features_content">
                                        <h2 className="sec_title"><Trans i18nKey="Audit.Reports of Risks.Title" /></h2>
                                        {t('Audit.Reports of Risks.Reports', { returnObjects: true })?.map((item, index) => {
                                            const currentNo = (index + 1) > 9 ? (index + 1) : `0${(index + 1)}`

                                            return <div key={item.Title} className="singleFeature">
                                                <div className="f_count">{currentNo}</div>
                                                <h3><Trans i18nKey={item.Title} /></h3>

                                                {item.Description && <p>
                                                    <Trans i18nKey={item.Description} />
                                                </p>
                                                }
                                            </div>

                                        })}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-md-2 col-lg-2 col-xl-3" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6" />
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default ReportsOfCyberRisks
