import React from 'react';
import Testimonials from "../../components/Testimonials";
import SearchForm from "../../components/SearchForm";
import PopupMenu from "../../components/PopupMenu";
import Services from "../../components/Services";
import BestAgency from "../../components/BestAgency";
import FiveReasons from "../../components/FiveReaseons";
import Team from "../../components/Team";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import HomePageBanner from "../../components/HomePageBanner";
import PortofolioListHeaderCategories from "../../components/PortofolioListHeaderCategories";
import PortofolioListImageBanner from "../../components/PortofolioListImageBanner";
import PortofolioDetailSection from "../../components/PortofolioDetailSection";
import { useTranslation } from '../../lib/i18n';

const PortofolioItemPage = (props) => {
    const { match: { params } } = props;
    const { t } = useTranslation();
    const currentProjectKey = `${params.projectId}`;

    let currentProject = t('portofolio:Projects', { returnObjects: true })?.filter(function (project, index) {
        return project.key === currentProjectKey
    })[0];
    return (
        <>
            <SearchForm />
            <PortofolioListImageBanner title={`${currentProject?.name}`} />
            <PortofolioDetailSection project={currentProject} />
            {/*<ConnectWithUs title={"it de!"}/>*/}
            <Footer />
        </>
    );
};

export default PortofolioItemPage;