import React, {Component} from 'react';
import Footer from "../../components/Footer";
import PrivacyContent from "../../components/PrivacyContent";

export default class PrivacyPage extends Component {
    render() {
        return (
            <>
                <PrivacyContent/>
                <Footer/>
            </>
        );
    }
};
