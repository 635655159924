import React, {Component} from 'react';
import ContactMap from "./ContactMap";

class ContactMapContainer extends Component {
    componentDidMount() {
        setTimeout(()=>this.initGMaps(),700);
    }

    initGMaps() {
        const {GMaps, google, $} = window;

        if ($('#gmap').length > 0) {
            var contact_map;
            contact_map = new GMaps({
                el: '#gmap',
                lat: 52.078800,
                lng: 4.336753,
                scrollwheel: false,
                zoom: 12,
                zoomControl: false,
                panControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                overviewMapControl: false,
                clickable: false
            });
            // contact_map.addMarker({
            //     lat: 52.078800,
            //     lng: 4.336753,
            //     icon: '/assets/images/location_pin.png',/*todo update marker*/
            //     // animation: google.maps.Animation.DROP//todo uncomment this
            // });
            var styles = [
                {
                    'featureType': 'road',
                    'stylers': [
                        {'color': '#ffffff'}
                    ]
                }, {
                    'featureType': 'water',
                    'stylers': [
                        {'color': '#ededed'}
                    ]
                }, {
                    'featureType': 'landscape',
                    'stylers': [
                        {'color': '#f7f7f7'}
                    ]
                }, {
                    'elementType': 'labels.text.fill',
                    'stylers': [
                        {'color': 'transparent'}
                    ]
                }, {
                    'featureType': 'poi',
                    'stylers': [
                        {'color': '#e5e5e5'}
                    ]
                }, {
                    'elementType': 'labels.text',
                    'stylers': [
                        {'saturation': 1},
                        {'weight': 0.1},
                        {'color': '#818181'}
                    ]
                }
            ];
            //todo uncomment
            // contact_map.addStyle({
            //     styledMapName: 'Styled Map',
            //     styles: styles,
            //     mapTypeId: 'map_style'
            // });
            // contact_map.setStyle('map_style');
        }
    }

    render() {
        return (
            <>
                <ContactMap/>
            </>
        );
    }
}

export default ContactMapContainer;
