import React from 'react';

import { ReactComponent as Innovative } from '../../assets/images/about/ic_innovative.svg'
import { ReactComponent as Sustainable } from '../../assets/images/about/ic_sustainable.svg'
import { ReactComponent as Accountable } from '../../assets/images/about/ic_accountable.svg'
import { ReactComponent as Honest } from '../../assets/images/about/ic_honest.svg'
import { ReactComponent as Efficient } from '../../assets/images/about/ic_efficient.svg'
import { ReactComponent as Transparent } from '../../assets/images/about/ic_transparent.svg'
import { useTranslation } from '../../lib/i18n';


const Values = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="values_bg commonSection pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">

                            <h2 className="sec_title white">{t('AboutUs.Values.Title')}
                            </h2>
                            <br />
                        </div>
                    </div>
                    <div className="row custom_column">
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Innovative />
                                            <h3>{t('AboutUs.Values.Inovative')}</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Innovative />
                                            <h3>{t('AboutUs.Values.Inovative')}</h3>
                                        </div>

                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Sustainable />
                                            <h3>{t('AboutUs.Values.Sustainable')}</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Sustainable />
                                            <h3>{t('AboutUs.Values.Sustainable')}</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Accountable />
                                            <h3>{t('AboutUs.Values.Accountable')}</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Accountable />
                                            <h3>{t('AboutUs.Values.Accountable')}</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Honest />
                                            <h3>{t('AboutUs.Values.Honest')}</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Honest />
                                            <h3>{t('AboutUs.Values.Honest')}</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Efficient />
                                            <h3>{t('AboutUs.Values.Efficient')}</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Efficient />
                                            <h3>{t('AboutUs.Values.Efficient')}</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-2">
                            <a href="#" className="icon_box_1 text-center">
                                <div className="flipper">
                                    <div className="front">
                                        <div className="icon">
                                            <Transparent />
                                            <h3>{t('AboutUs.Values.Transparent')}</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="icon">
                                            <Transparent />
                                            <h3>{t('AboutUs.Values.Transparent')}</h3>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Values;
