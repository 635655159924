import React, { Component } from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import PageTitle from "../../components/PageTitle";
import OurServiceDetail from "../../components/OurServiceDetail";
import image1 from "../../assets/images/mainteinance/mainteinance.png";
import image2 from "../../assets/images/mainteinance/the-purpose-of-mainteinance.png";
import { Trans, useTranslation } from '../../lib/i18n';

const img = { image1, image2 };

const MaintenancePage = () => {
    const { t } = useTranslation()
    return (
        <>
            <SearchForm />
            <PageTitle
                title={<Trans i18nKey='Maintenance.Title' />}
                subTitle={<Trans i18nKey='Maintenance.SubTitle' />}
                sectionClassName="serviceDetailBackgroundBlack"
            />

            {t('Maintenance.Services', { returnObjects: true })?.map((item, index) => <OurServiceDetail key={index} classLeft={item.ClassLeft} classRight={item.ClassRight}
                childrenRight={<>
                    {item.Title && <h2 className="sec_title">{t(item.Title)}</h2>}
                    <Trans i18nKey={item.Description} />
                </>}
                childrenLeft={<>
                    {item.Img &&
                        <img src={img[item.Img]} className={"img_full_width"} alt="" />
                    }
                </>}
            />)}

            <GetYourQuote title={<Trans i18nKey="Common.Let's start" />} />
            <Footer />
        </>
    );
};

export default MaintenancePage;