import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import { Trans, useTranslation } from '../../lib/i18n';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <footer className="footer_1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-6 col-md-5">
                            <aside className="widget aboutwidget">
                                <Link to="/"><img src={logo} alt="cyber hybrid logo" /></Link>
                                <Link to="#"><img src="src/assets/images/logo.png" alt="" /></Link>

                                <div className="row">
                                    <div className="col-md-6">
                                        <Link to="/"><p>{t('Footer.Home')}</p></Link>
                                        <Link to="/about"><p>{t('Footer.About us')}</p></Link>
                                        <Link to="/portofolio"><p>{t('Footer.Portofolio')}</p></Link>
                                        <Link to="#"><p>{t('Footer.Services')}</p></Link>
                                        <Link to="/services/it-development">    <p>{t('Footer.IT Development')}</p></Link>
                                        <Link to="/services/audit">     <p>{t('Footer.Audit')}</p></Link>
                                        <Link to="/services/maintenance">   <p>{t('Footer.Maintenance')}</p></Link>
                                        <Link to="/services/consulting"><p>{t('Footer.Consulting')}</p></Link>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/cookie-policy"><p>{t('Footer.Cookie Policy')}</p></Link>
                                        <Link to="/privacy"><p>{t('Footer.Privacy Policy')}</p></Link>
                                        <Link to="/disclaimer"><p>{t('Footer.Disclaimer')}</p></Link>
                                        <Link to="/contact"><p>{t('Footer.Contact us')}</p></Link>
                                    </div>
                                </div>

                            </aside>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-md-4">
                            <aside className="widget contact_widgets">
                                <h3 className="widget_title">{t('Footer.Contact')}</h3>
                                <p>
                                    <Trans i18nKey="Common.Address" />
                                </p>
                                <p>
                                    <Trans i18nKey="Common.Email" />
                                </p>
                            </aside>
                        </div>
                        <div className="col-lg-3 col-sm-2 col-md-3">
                            <aside className="widget social_widget">
                                <h3 className="widget_title">{t('Footer.Social')}</h3>
                                <ul>
                                    <li><a href="https://twitter.com/hybrid_cyber"><i className="fa fa-twitter"></i>{t('Social.Twitter')}</a></li>
                                    <li><a href="https://www.facebook.com/Cyber-Hybrid-103051907856184/"><i className="fa fa-facebook-square"></i>{t('Social.Facebook')}</a></li>
                                    <li><a href=""><i className="fa fa-youtube-play"></i>{t('Social.Youtube')}</a></li>
                                    <li><a href="https://www.linkedin.com/company/31452855/"><i className="fa fa-linkedin"></i>{t('Social.Linkedin')}</a></li>
                                    <li><a href="https://www.instagram.com/cyberhybridnl/"><i className="fa fa-instagram"></i>{t('Social.Instagram')}</a></li>

                                </ul>
                            </aside>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 text-center">
                            <div className="copyright">
                                {t('Common.Copy Right')}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
