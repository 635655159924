import React from 'react';
import mission from "../../assets/images/about/mission-vision.png"
import { Trans, useTranslation } from '../../lib/i18n';

const MissionVision = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="commonSection mission-vision ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-md-6 PR_79">
                            <h4 className="sub_title">{t('AboutUs.Mission Vision.Subtitle')}</h4>
                            <h2 className="sec_title MB_45">{t('AboutUs.Mission Vision.Title')}</h2>
                            <p className="sec_desc">
                                <Trans i18nKey="AboutUs.Mission Vision.Description" />
                            </p>
                            <p className="sec_desc">
                                <Trans i18nKey="AboutUs.Mission Vision.Vision" />
                            </p>
                            <p className="sec_sub_title">
                                <Trans i18nKey="AboutUs.Mission Vision.Goal" />
                            </p>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-md-6">
                            <img src={mission} alt="" className="img_full_width" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MissionVision;
