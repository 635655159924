import i18n from 'i18next';
import React from 'react';
import {initReactI18next, Trans as I18nTrans, useTranslation as i18nTranslation, TransProps} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEnUS from '../locales/en-US/translation.json';
import policyEnUS from '../locales/en-US/policy.json';
import cookieEnUS from '../locales/en-US/cookie.json';
import disclaimerEnUS from '../locales/en-US/disclaimer.json';
import portofolioEnUS from '../locales/en-US/portofolio.json';

import translationNlNL from '../locales/nl-NL/translation.json';
import policyNlNL from '../locales/nl-NL/policy.json';
import cookieNlNL from '../locales/nl-NL/cookie.json';
import disclaimerNlNL from '../locales/nl-NL/disclaimer.json';
import portofolioNlNL from '../locales/nl-NL/portofolio.json';

const resources = {
  en: {
    translation: translationEnUS,
    policy: policyEnUS,
    cookie: cookieEnUS,
    disclaimer: disclaimerEnUS,
    portofolio: portofolioEnUS
  },
  nl: {
    translation: translationNlNL,
    policy: policyNlNL,
    cookie: cookieNlNL,
    disclaimer: disclaimerNlNL,
    portofolio: portofolioNlNL
  }
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV !== 'production',
  ns: ['translation', 'policy', 'cookie', 'disclaimer', 'portofolio'],
  defaultNS: 'translation',

  interpolation: {
    escapeValue: false,
  },
});

/**
 *
 * @param props {TransProps}
 * @returns {JSX.Element}
 * @constructor
 */
export const Trans = (props) => {
  return (
    <I18nTrans
      {...props}
      components={{
        ...props?.components,
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        a: <a children=" "/>,
        span: <span/>,
        b: <b/>,
        ul: <ul/>,
        li: <li/>,
        div: <div/>,
        br: <br/>,
        p: <p/>
      }}
    />
  );
};

export const useTranslation = (ns, options) => {
  const {t, i18n} = i18nTranslation(ns, options);

  const changeLanguage = React.useCallback(
    (language) => {
      window.localStorage.setItem('lang', language);
      i18n.changeLanguage(language);
    },
    [i18n],
  );

  return {t, i18n: {...i18n, changeLanguage}};
};
