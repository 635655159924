import React from 'react';
import { useTranslation } from '../../lib/i18n';

const PortofolioListHeaderCategories = () => {
    const { t } = useTranslation();

    return (
        <>
            <>
                <section className="portofolioListCategoriesFilterContainer">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="folio_mixing">
                                <ul>
                                    {t('Portofolio.Categories', { returnObjects: true })?.map((category, index) =>
                                        <li key={category} className={`filter ${index ? '' : 'active'}`} data-filter={category}>{category}</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </>
    );
};

export default PortofolioListHeaderCategories;
