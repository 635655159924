import React from 'react';
import { useTranslation } from '../../lib/i18n';

const DisclaimerContent = () => {
    const { t } = useTranslation();

    return (
        <div dangerouslySetInnerHTML={{ __html: t('disclaimer:Disclaimer') }} />
    );
}

export default DisclaimerContent;
