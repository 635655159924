import React from 'react';
import teamBio from "../../assets/images/about/team-bio.png"
import { Trans, useTranslation } from '../../lib/i18n';

const TeamBioDescription = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="commonSection mission-vision no-padding">
                <div className="container d-sm-none">
                    <div className="row">
                        <div className="col-lg-8 col-sm-8 col-md-8 overlap-image">
                            <img src={teamBio} alt="" className="img_full_width" />
                        </div>
                        <div className="col-lg-4 col-sm-4 col-md-4" />
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-sm-5 col-md-5" />
                        <div className="col-lg-5 col-sm-5 col-md-5 commonSection pl-15 z-index mission-vision">
                            <h2 className="sec_title MB_45">{t('AboutUs.Team Bio.Title')}
                            </h2>
                            <p className="sec_desc">
                                <Trans i18nKey="AboutUs.Team Bio.Description" />
                            </p>
                            <p className="sec_desc">
                                <Trans i18nKey="AboutUs.Team Bio.Team" />
                            </p>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-md-2" />

                    </div>
                </div>
            </section>
        </>
    );
};

export default TeamBioDescription;
