import React, { Component } from 'react';
import SearchForm from "../../components/SearchForm";
import Footer from "../../components/Footer";
import GetYourQuote from "../../components/GetYourQuote";
import PageTitle from "../../components/PageTitle";
import OurServiceDetail from "../../components/OurServiceDetail";
import image2 from "../../assets/images/home/product_market_strategy.png";
import image3 from "../../assets/images/home/mobile_mvp_info.png";
import { Trans, useTranslation } from '../../lib/i18n';

const img = {
    image2, image3
}

const ItDevelopmentPage = () => {
    const { t } = useTranslation();

    return <>
        <SearchForm />
        <PageTitle
            title={<Trans i18nKey='It Development.Title' />}
            subTitle={<Trans i18nKey='It Development.SubTitle' />}
            sectionClassName="serviceDetailBackgroundBlack"
        />

        {t('It Development.Services', { returnObjects: true })?.map((item, index) => <OurServiceDetail key={index} classLeft={item.ClassLeft} classRight={item.ClassRight}
            childrenLeft={<>
                <h2 className="sec_title">{t(item.Title)}</h2>
                <p className="sec_desc">
                    <Trans i18nKey={item.Description} />
                </p></>}
            childrenRight={<>
                {item.List && <ul className={"services_list"}>
                    {item.List.map((i, k) => <li key={k}>{i}</li>)}
                </ul>}

                {item.Img &&
                    <img src={img[item.Img]} className={"img_full_width"} alt="" />
                }
            </>}
        />)}
      
        <GetYourQuote title={<Trans i18nKey="Common.Let's start" />} />
        <Footer />
    </>
}

export default ItDevelopmentPage;


