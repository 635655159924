import React from 'react';
import { Trans, useTranslation } from '../../lib/i18n';
import { TextAnimation } from '../TextAnimation';

const TextAnimationComp = (props) => {
    const { services } = props;

    return <TextAnimation withSpace={true} className="css-typing">
        {React.Children.map(services, item => <React.Fragment key={item}>{item}</React.Fragment>)}
    </TextAnimation>
}

const HomePageBanner = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="pageBanner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner_content text-center">
                                <h4>{t('HomePage.Banner.Welcome')}</h4>
                                <h2>
                                    <Trans
                                        i18nKey="HomePage.Banner.Description.Title"
                                        components={{
                                            services: <TextAnimationComp services={t('HomePage.Banner.Description.services', { returnObjects: true })} />,
                                            advantage: <TextAnimationComp services={t('HomePage.Banner.Description.advantage', { returnObjects: true })} />,
                                        }} />
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
        ;
};

export default HomePageBanner;
