import React from 'react';
import image from "../../assets/images/home_1/5_reasons.png";
import { Trans, useTranslation } from '../../lib/i18n';

const FiveReasons = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="service_section commonSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h4 className="sub_title red_color">{t('HomePage.Five Reasons.Subtitle')}</h4>
                            <h2 className="sec_title white"><Trans i18nKey='HomePage.Five Reasons.Title' /></h2>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-md-5">
                        <div className="chose_img">
                            <img src={image} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 col-sm-7 col-md-7">
                        <div className="wh_choose">
                            <ul>
                                {t('HomePage.Five Reasons.Reasons', { returnObjects: true })?.map(reasons => <li key={reasons}><i className="fa fa-check-square"></i>{reasons}</li>)}
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
};

export default FiveReasons;