import React from 'react';
import { useTranslation } from '../lib/i18n';

const CookiePolicyContent = () => {
    const { t } = useTranslation();

    return (
        <div dangerouslySetInnerHTML={{ __html: t('cookie:Cookie Policy') }} />
    );
}

export default CookiePolicyContent;
