import React from 'react';
import {Link} from 'react-router-dom';
import logo from "../../assets/images/logo.png";

const FacebookChat = () => {

    window.fbAsyncInit = function () {
        // eslint-disable-next-line no-undef
        FB.init({
            xfbml: true,
            version: 'v8.0'
        });
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    return (
        <>
            <div id="fb-root"></div>
            
            <div className="fb-customerchat"
                 pageId="103051907856184"
                 themeColor="#e62b4a">
            </div>
        </>
    );
};

export default FacebookChat;
