import React, {Component} from 'react';
import Footer from "../../components/Footer";
import PrivacyContent from "../../components/PrivacyContent";
import CookiePolicyContent from "../../CookiePolicyContent";

export default class CookiePolicyPage extends Component {
    render() {
        return (
            <>
                <CookiePolicyContent/>
                <Footer/>
            </>
        );
    }
};
