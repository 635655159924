import React from 'react';
import { useForm } from 'react-hook-form'
import { Link } from "react-router-dom";
import { Trans, useTranslation } from '../../lib/i18n';

const ContactForm = (props) => {
    const { t } = useTranslation();

    const { register, handleSubmit, errors } = useForm();

    const renderMessage = React.useMemo(() => {
        let message = t('Contact.Contact Form.Send Message');
        if (Object.keys(errors).length) {
            message = t('Contact.Contact Form.Failed');
        } else if (props.submitError) {
            message = t('Contact.Contact Form.Try Again')
        } else if (props.submitSuccess) {
            message = t('Contact.Contact Form.Thank You')
        }

        return <>
            <span>{message}</span>
        </>
    }, [errors, props.submitError, props.submitSuccess, t]);

    return (
        <>
            <section className="commonSection ContactPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h4 className="sub_title">{t('Contact.Contact Form.Subtitle')}</h4>
                            <h2 className="sec_title">{t('Contact.Contact Form.Title')}</h2>
                            <p className="sec_desc">
                                {t('Contact.Contact Form.Description')}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-lg-offset-2 col-sm-12 col-md-10 col-md-offset-1">
                            <form onSubmit={handleSubmit(props.onSubmit)} className="contactFrom" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <input
                                            className={['input-form required', errors.f_name && 'reqError'].join(' ')}
                                            type="text" name="f_name" id="f_name" ref={register({ required: true })}
                                            placeholder={t('Common.Labels.First Name')} />
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <input
                                            className={['input-form required', errors.l_name && 'reqError'].join(' ')}
                                            type="text" name="l_name" id="l_name" ref={register({ required: true })}
                                            placeholder={t('Common.Labels.Last Name')} />
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <input className={['input-form required', errors.email && 'reqError'].join(' ')}
                                            type="email" name="email" id="email" ref={register({ required: true })}
                                            placeholder={t('Common.Labels.Email')} />
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <input className={['input-form required', errors.phone && 'reqError'].join(' ')}
                                            type="text" name="phone" id="phone" ref={register({ required: true })}
                                            placeholder={t('Common.Labels.Phone Number')} />
                                    </div>
                                    <div className="col-lg-12 col-sm-12">
                                        <textarea
                                            className={['input-form required', errors.con_message && 'reqError'].join(' ')}
                                            name="con_message" id="con_message" ref={register({ required: true })}
                                            placeholder={t('Common.Labels.Message')}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <p className="sec_desc">
                                            <Trans i18nKey="Contact.Contact Form.Disclaimer" />
                                        </p>
                                    </div>
                                </div>
                                <button className="common_btn red_bg" type="submit" id="con_submit"
                                    disabled={props.submitSuccess}>
                                    {renderMessage}
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactForm;